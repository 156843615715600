<template>
  <div class="about content py-sm-5 bg-custom">
    <div class="container-fluid pt-5 mt-5">
      <div class="row p-5 text-light">
        <h1 class="title text-start fw-bold">About Us</h1>
        <h4 class="text-capitalize text-start mt-5 mb-3 style fw-bold">
          会社概要
        </h4>
        <div class="col-sm col-md-12 mb-4">
          <table class="company">
            <tr v-for="nd in cdata">
              <th>{{ nd.title }}</th>
              <td>{{ nd.desc }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row p-5 text-light">
        <!-- About Title -->
        <!-- Column -->
        <!--
        <div class="col-sm px-5">
          <h4 class="text-capitalize text-start mt-5 mb-3 style fw-bold">
            沿革
          </h4>
          <table class="history">
            <tr v-for="hh in history">
              <td>{{ hh.year }}年</td>
              <td>{{ hh.title }}</td>
              <td>{{ hh.desc }}</td>
            </tr>
          </table>
        </div>
      --></div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  name: "About",
  setup() {
    // Image source
    let imgsrc;
    let logo;

    try {
      //Check availability
      imgsrc = ref(require("@/assets/images/gate.jpg"));
      logo = ref(require("@/assets/images/logo.svg"));
    } catch (error) {
      console.error(error);
    }
    return {
      imgsrc,
      logo,
    };
  },
  data() {
    return {
      cdata: [
        { title: "社名", desc: "インタリオ株式会社" },
        { title: "資本金", desc: "5,120,000円" },
        { title: "本店所在地", desc: "埼玉県蕨市北町1-25-12" },
        { title: "設立", desc: "2008年 12月" },
        { title: "取引銀行", desc: "三菱UFJ銀行、Paypay銀行" },
      ],
      history: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.about {
  min-height: 900px;
  h1,
  h4 {
    color: #333;
  }
}
table.company {
  color: #333;
  margin-left: 150px;
  width: 100%;
  max-width: 600px;
  td {
    text-align: left;
  }
  tr {
    border-bottom: solid 1px rgb(104, 97, 97);
  }
}

.logo {
  width: 380px;
}
.bg-custom {
  background-image: linear-gradient(to right, #ffffff, rgb(201, 168, 188));
}
p:first-child {
  color: #667eea;
}

p {
  text-align: justify;
  letter-spacing: 3px;
  line-height: 1.6;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.Stars {
  background-color: #334155;
}

.slanted {
  transform: skewY(3deg);
  transform: skewX(-1deg);
}
.slanted > img {
  box-shadow: 10px 7px 10px black;
}
</style>
